body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f8f9fa;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1, h2, h3 {
  font-family: 'Helvetica', sans-serif;
  color: #007bff;
}

.navbar {
  background-color: #f8f9fa !important;
}

.nav-link {
  color: #333 !important;
}

.hero-section {
  background-color: #ffffff;
  padding: 100px 0;
  text-align: center;
}

.hero-section h1 {
  font-size: 3rem;
  color: #333;
}

.hero-section p {
  font-size: 1.2rem;
  color: #666;
}

.hero-section img {
  max-width: 100%;
  border-radius: 10px;
}

.stats-section {
  background-color: #e9ecef;
  padding: 50px 0;
  text-align: center;
}

.stats-section h3 {
  font-size: 2.5rem;
  color: #007bff;
}

.stats-section p {
  font-size: 1.2rem;
  color: #666;
}

.about-section {
  background-color: #ffffff;
  padding: 50px 0;
}

.about-section h2 {
  font-size: 2rem;
  color: #333;
}

.about-section p {
  font-size: 1.2rem;
  color: #666;
}

.about-section ul {
  list-style: none;
  padding: 0;
}

.about-section ul li {
  font-size: 1.2rem;
  color: #666;
}

.about-section img {
  max-width: 100%;
  border-radius: 10px;
}

.programs-section {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.programs-section h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 40px;
  text-align: center;
}

.card {
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-title {
  font-size: 1.5rem;
  color: #007bff;
}

.card-text {
  font-size: 1rem;
  color: #666;
}

.footer-section {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-section p {
  margin: 0;
  font-size: 1rem;
}
