.btn-principal {
  background-color: #b38f8f !important;
  color: #fff !important;
  border-radius: 30px;
  padding: 8px 30px;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;
  font-size: 14px;
}
.btn-principal:hover {
  border-color: #b38f8f !important;
  background-color: transparent !important;
  color: #b38f8f !important;
}

.form-signin {
  max-width: 330px;
  padding: 1rem;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
