.EmBreve.card {
    background: url("../../../public/assets/img/emBreve/background-1.png");
    background-position: 60% 80%;
    background-size: cover;
    width: 100%;
    height: 100vh !important;
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.EmBreve.card-body {
    background: url("../../../public/assets/img/emBreve/background-2.png");
    background-position: center;
    background-size: cover;
    padding: 20% 5% !important;
    margin:  50% 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    height: 100%;
}

.EmBreve.card-body h1 {
color: #3C291F;
font-family: "Oswald", sans-serif;
font-size: 3rem;
margin-bottom: 0;
}

.EmBreve.card-body em {
    color: #fff;
    margin-top: 0;
    font-family: "Playwrite CU", cursive;
}

.EmBreve.card-body hr {
    width: 80px;
    margin: 20px auto;
    color: #3C291F;
}

.EmBreve.card-body h2 {
    color: #fff;
    font-family: "Oswald", sans-serif;
    
}

.EmBreve.card-body i {
    color: #fff;
}

.EmBreve.card-body p {
    color: #fff;
}