/* Fonts */
:root {
    --default-font: "Roboto",  system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Roboto",  sans-serif;
    --nav-font: "Lato",  sans-serif;
  }
  
  /* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
  :root { 
    --background-color: #e7ddd9; /* Background color for the entire website, including individual sections */
    --default-color: #212529; /* Default color used for the majority of the text content across the entire website */
    --heading-color: #865243; /* Color for headings, subheadings and title throughout the website */
    --accent-color: #b38f8f; /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff; /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff; /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
  
  }
  
  /* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
  :root {
    --nav-color: #212529;  /* The default color of the main navmenu links */
    --nav-hover-color: #865243; /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff; /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff; /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #212529; /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #865243; /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
  }
  
  /* Color Presets */
  
  .light-background {
    --background-color: #f3f4f4;
    --surface-color: #ffffff;
  }
  
  .dark-background {
    --background-color: #060606;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #252525;
    --contrast-color: #ffffff;
  }
  
  /* Smooth scroll */
  :root {
    scroll-behavior: smooth;
  }
  
  /*--------------------------------------------------------------
  # General
  --------------------------------------------------------------*/
  body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
    overflow-x: hidden;
  }
  
  a {
    color: var(--accent-color) !important;
    text-decoration: none !important;
    transition: 0.3s;
  }
  
  a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
  }
  
  /*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
  .header {
    color: var(--default-color);
    background-color: #b38f8f;
    transition: all 0.5s;
    z-index: 997;
  }
  
  .header .logo {
    line-height: 1;
  }
  
  .header .logo img {
    max-height: 80px;
    margin-right: 8px;
  }
  
  .scrolled .header {
    box-shadow: 0px 0 18px color-mix(in srgb, var(--default-color), transparent 85%);
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /* Navmenu - Desktop */
  @media (min-width: 1200px) {
    .navmenu {
      padding: 0;
    }
  
    .navmenu ul {
      margin: 0;
      padding: 0;
      display: flex;
      list-style: none;
      align-items: center;
    }
  
    .navmenu li {
      position: relative;
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: #fff !important;
      padding: 18px 15px;
      font-size: 16px;
      font-family: var(--nav-font);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      transition: 0.3s;
    }
  
    .navmenu li:last-child a {
      padding-right: 0;
    }
  
    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color) !important;
    }
  
    .navmenu .dropdown ul {
      margin: 0;
      padding: 10px 0;
      background: var(--nav-dropdown-background-color);
      display: block;
      position: absolute;
      visibility: hidden;
      left: 14px;
      top: 130%;
      opacity: 0;
      transition: 0.3s;
      border-radius: 4px;
      z-index: 99;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 85%);
    }
  
    .navmenu .dropdown ul li {
      min-width: 200px;
    }
  
    .navmenu .dropdown ul a {
      padding: 10px 20px;
      font-size: 15px;
      text-transform: none;
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a i {
      font-size: 12px;
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown:hover>ul {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  
    .navmenu .dropdown .dropdown ul {
      top: 0;
      left: -90%;
      visibility: hidden;
    }
  
    .navmenu .dropdown .dropdown:hover>ul {
      opacity: 1;
      top: 0;
      left: -100%;
      visibility: visible;
    }
  }
  
  /* Navmenu - Mobile */
  @media (max-width: 1199px) {
    .mobile-nav-toggle {
      color: var(--nav-color);
      font-size: 28px;
      line-height: 0;
      margin-right: 10px;
      cursor: pointer;
      transition: color 0.3s;
    }
  
    .navmenu {
      padding: 0;
      z-index: 9997;
    }
  
    .navmenu ul {
      display: none;
      position: absolute;
      inset: 60px 20px 20px 20px;
      padding: 10px 0;
      margin: 0;
      border-radius: 6px;
      background-color: var(--nav-mobile-background-color);
      overflow-y: auto;
      transition: 0.3s;
      z-index: 9998;
      box-shadow: 0px 0px 30px color-mix(in srgb, var(--default-color), transparent 90%);
    }
  
    .navmenu a,
    .navmenu a:focus {
      color: var(--nav-color);
      padding: 10px 20px;
      font-family: var(--nav-font);
      font-size: 17px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      white-space: nowrap;
      transition: 0.3s;
    }
  
    .navmenu a i,
    .navmenu a:focus i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: 0.3s;
      background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }
  
    .navmenu a i:hover,
    .navmenu a:focus i:hover {
      background-color: var(--accent-color);
      color: var(--contrast-color);
    }
  
    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
      color: var(--nav-hover-color);
    }
  
    .navmenu .active i,
    .navmenu .active:focus i {
      background-color: var(--accent-color);
      color: var(--contrast-color);
      transform: rotate(180deg);
    }
  
    .navmenu .dropdown ul {
      position: static;
      display: none;
      z-index: 99;
      padding: 10px 0;
      margin: 10px 20px;
      background-color: var(--nav-dropdown-background-color);
      border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
      box-shadow: none;
      transition: all 0.5s ease-in-out;
    }
  
    .navmenu .dropdown ul ul {
      background-color: rgba(33, 37, 41, 0.1);
    }
  
    .navmenu .dropdown ul a {
      color: var(--nav-dropdown-color);
    }
  
    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
      color: var(--nav-dropdown-hover-color);
    }
  
    .navmenu .dropdown>.dropdown-active {
      display: block;
      background-color: rgba(33, 37, 41, 0.03);
    }
  
    .mobile-nav-active {
      overflow: hidden;
    }
  
    .mobile-nav-active .mobile-nav-toggle {
      color: #fff;
      position: absolute;
      font-size: 32px;
      top: 15px;
      right: 15px;
      margin-right: 0;
      z-index: 9999;
    }
  
    .mobile-nav-active .navmenu {
      position: fixed;
      overflow: hidden;
      inset: 0;
      background: rgba(33, 37, 41, 0.8);
      transition: 0.3s;
    }
  
    .mobile-nav-active .navmenu>ul {
      display: block;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
  .footer {
    background-color: var(--title-color);
    color: var(--default-color);
    padding: 60px 0;
    font-size: 14px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .footer a {
    color: var(--default-color) !important;
  }
  
  .footer a:hover {
    color: color-mix(in srgb, var(--default-color), black 50%) !important;
  }
  
  .footer .copyright {
    margin-top: 50px;
    position: relative;
    padding-top: 20px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
  }
  
  .footer .copyright p,
  .footer .copyright .credits {
    margin: 2px 0;
  }
  
  .footer .btn-learn-more {
    background-color: var(--accent-color);
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
    color: var(--contrast-color) !important;
  }
  
  .footer .btn-learn-more:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color) !important;
  }
  
  .footer .widget .widget-heading {
    font-size: 15px;
    color: var(--heading-color);
    margin-bottom: 20px;
  }
  
  .footer .widget ul li {
    margin-bottom: 10px;
    line-height: 1.5;
  }
  
  .footer .widget ul li a {
    color: color-mix(in srgb, var(--heading-color), transparent 50%);
  }
  
  .footer .widget ul li a:hover {
    text-decoration: none;
    color: var(--heading-color);
  }
  
  .footer .widget .footer-blog-entry .date {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 12px;
  }
  
  .footer .form-control {
    background: none;
    height: 42px;
  }
  
  .footer .form-control:active,
  .footer .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .footer .custom-select {
    border: 2px solid color-mix(in srgb, var(--default-color), transparent 90%);
    height: 42px;
  }
  
  .footer .custom-select:active,
  .footer .custom-select:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .footer .social-icons li {
    display: inline-block;
  }
  
  .footer .social-icons li a {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
    background: color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .footer .social-icons li a span {
    color: color-mix(in srgb, var(--heading-color), transparent 0%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s all ease-in-out;
  }
  
  .footer .social-icons li a:hover {
    background: var(--accent-color);
  }
  
  .footer .social-icons li a:hover span {
    color: var(--contrast-color);
  }
  
  .footer .social-icons li:first-child a {
    padding-left: 0;
  }
  
  .footer .footer-subscribe form {
    position: relative;
  }
  
  .footer .footer-subscribe .form-control {
    font-size: 14px;
    height: 42px;
    border: 2px solid color-mix(in srgb, var(--default-color), transparent 40%);
    background: none;
    color: var(--heading-color);
    padding-right: 40px;
  }
  
  .footer .footer-subscribe .form-control:focus {
    border-color: color-mix(in srgb, var(--default-color), transparent 10%);
  }
  
  .footer .footer-subscribe .form-control::-webkit-input-placeholder {
    color: color-mix(in srgb, var(--heading-color), transparent 70%);
  }
  
  .footer .footer-subscribe .form-control::-moz-placeholder {
    color: color-mix(in srgb, var(--heading-color), transparent 70%);
  }
  
  .footer .footer-subscribe .form-control:-ms-input-placeholder {
    color: color-mix(in srgb, var(--heading-color), transparent 70%);
  }
  
  .footer .footer-subscribe .form-control:-moz-placeholder {
    color: color-mix(in srgb, var(--heading-color), transparent 70%);
  }
  
  .footer .footer-subscribe .btn-link {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    background-color: none;
    border-color: none;
    position: absolute;
    line-height: 0;
    color: color-mix(in srgb, var(--heading-color), transparent 20%);
    top: 50%;
    right: 10px;
    transform: translateY(-50%) rotate(0deg);
  }
  
  .footer .footer-subscribe .btn-link:hover,
  .footer .footer-subscribe .btn-link:focus,
  .footer .footer-subscribe .btn-link:active {
    text-decoration: none;
  }
  
  .footer .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-top: 10px;
    font-weight: 600;
    border-radius: 4px;
  }
  
  .footer .loading {
    display: none;
    background: var(--background-color);
    text-align: center;
    padding: 15px;
    margin-top: 10px;
  }
  
  .footer .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: subscription-loading 1s linear infinite;
  }
  
  @keyframes subscription-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: var(--background-color);
    transition: all 0.6s ease-out;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
  .scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
  }
  
  .scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
  }
  
  .scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
  }
  
  .scroll-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
  @media screen and (max-width: 768px) {
    [data-aos-delay] {
      transition-delay: 0 !important;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
  .page-title {
    background-color: var(--background-color);
    padding: 25px 0;
    position: relative;
  }
  
  .page-title .page-title-heading {
    font-size: 26px;
    color: var(--heading-color);
    font-weight: 300;
  }
  
  .page-title .custom-breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  
  .page-title .custom-breadcrumb a {
    color: var(--default-color);
  }
  
  .page-title .custom-breadcrumb a:hover {
    color: color-mix(in srgb, var(--default-color), black 90%);
  }
  
  /*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
  section,
  .section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 100px;
    overflow: clip;
  }
  
  @media (max-width: 1199px) {
  
    section,
    .section {
      scroll-margin-top: 66px;
    }
  }
  
  /*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
  .section-title {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
  }
  
  .section-title h2 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
  }
  
  .section-title p {
    font-size: 15px;
    margin-bottom: 10px;
  }
  
  /*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
  .about .btn-get-started {
    background-color: var(--accent-color);
    color: var(--contrast-color) !important;
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
  }
  
  .about .btn-get-started:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color) !important;
  }
  
  .about h1 {
    color: var(--heading-color);
    font-size: 50px;
  }
  
  .about p {
    line-height: 1.7;
    color: var(--default-color);
  }

  .about img {
    height: 400px;
    object-fit: contain;
  }
  
  .about .swiper-pagination {
    position: absolute;
    bottom: 30px;
  }
  
  .about .swiper-pagination .swiper-pagination-bullet {
    margin: 0 5px;
    background: #ffffff;
    opacity: 0.3;
  }
  
  .about .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--accent-color);
    opacity: 1;
  }
  
  .about .section-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .count-numbers .number {
    font-size: 1.8rem;
    color: var(--heading-color);
    position: relative;
    display: block;
    padding-bottom: 7px;
    margin-bottom: 10px;
  }
   .count-numbers .number:after {
    content: "";
    left: 0;
    bottom: 0;
    position: absolute;
    width: 20px;
    height: 2px;
    background: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # About 2 Section
  --------------------------------------------------------------*/
  .about-2 .content {
    background-color: var(--surface-color);
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .about-2 .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .about-2 .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .about-2 p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .about-2 .btn-get-started {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
  }
  
  .about-2 .btn-get-started:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color);
  }
  
  .about-2 .lead {
    line-height: 1.6;
    font-size: 18px;
    font-weight: normal;
    color: var(--default-color);
  }
  
  /*--------------------------------------------------------------
  # Services Section
  --------------------------------------------------------------*/
  .services {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .services .services-item .services-icon {
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .services .services-item .services-icon span {
    font-size: 4rem;
  }
  
  .services .services-item .services-icon img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    filter: grayscale(100%);
    transition: transform 0.5s ease;
    padding: 10px;
    border-radius: 100%;
  }
  
  .services .services-item .services-icon img:hover {
    filter: grayscale(0%);
    transform: scale(1.2);
  }
  
  .services .services-item h3 {
    font-size: 17px;
    font-weight: 400;
    color: var(--heading-color);
  }
  
  /*--------------------------------------------------------------
  # Call To Action Section
  --------------------------------------------------------------*/
  .call-to-action {
    --background-color: #000000;
    --default-color: #ffffff;
    --contrast-color: #ffffff;
    padding:  120px 0;
    position: relative;
    clip-path: inset(0);
  }
  
  .call-to-action img {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  
  .call-to-action:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 35%);
    position: absolute;
    inset: 0;
    z-index: 2;
  }
  
  .call-to-action .container {
    position: relative;
    z-index: 3;
  }
  
  .call-to-action h3 {
    color: var(--accent-color);
    font-size: 28px;
    font-weight: 700;
  }
  
  .call-to-action p {
    color: var(--contrast-color);
  }
  
  .call-to-action a {
    padding: 10px;
    background-color: #ffffff;
  }
  
  /*--------------------------------------------------------------
  # Blog Posts Section
  --------------------------------------------------------------*/
  .blog-posts .title-wrap {
    padding-bottom: 30px;
  }
  
  .blog-posts .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .blog-posts .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .blog-posts .post-entry {
    background-color: #fff;
    padding-bottom: 10px;
    border-radius: 10px;
  }
  
  .blog-posts .post-entry .thumb {
    margin-bottom: 20px;
  }
  
  .blog-posts .post-entry .thumb img {
    transition: 0.3s all ease;
    max-height: 240px;
    width: 100%;
    object-fit: cover;
  }
  
  .blog-posts .post-entry .thumb:hover img {
    opacity: 0.8;
  }
  
  .blog-posts .post-entry .meta {
    font-size: 12px;
    margin-bottom: 20px;
  }
  
  .blog-posts .post-entry .meta .cat {
    text-transform: uppercase;
    font-weight: normal;
    color: var(--heading-color);
  }
  
  .blog-posts .post-entry .meta .date {
    color: color-mix(in srgb, var(--default-color), transparent 25%);
  }
  
  .blog-posts .post-entry .post-content {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .blog-posts .post-entry .post-content h3 {
    font-size: 18px;
    line-height: 1.2;
  }
  
  .blog-posts .post-entry .post-content h3 a {
    color: var(--heading-color);
  }
  
  .blog-posts .post-entry .post-content h3 a:hover {
    color: var(--accent-color);
  }
  
  .blog-posts .author .pic {
    flex: 0 0 50px;
    margin-right: 20px;
  }
  
  .blog-posts .author .author-name {
    line-height: 1.3;
  }
  
  .blog-posts .author .author-name strong {
    color: var(--heading-color);
    font-weight: normal;
  }
  
  .blog-posts .author .author-name span {
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 25%);
  }
  
  /*--------------------------------------------------------------
  # Tabs Section
  --------------------------------------------------------------*/
  .tabs .service-item.no-shadow.link {
    box-shadow: none !important;
  }
  
  .tabs .service-item .service-icon {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    border-radius: 4px;
    position: relative;
    color: var(--accent-color);
    background-color: var(--surface-color);
    margin-right: 20px;
    font-size: 16px;
  }
  
  .tabs .service-item .service-contents h3 {
    font-size: 16px;
    color: var(--heading-color);
  }
  
  .tabs .service-item.link {
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 7px;
  }
  
  .tabs .service-item.link .service-contents {
    color: var(--default-color);
  }
  
  .tabs .service-item.link .service-contents *:last-child {
    margin-bottom: 0;
  }
  
  .tabs .service-item.link:hover {
    background: color-mix(in srgb, var(--default-color), transparent 96%);
  }
  
  .tabs .service-item.link:hover .service-icon {
    background-color: color-mix(in srgb, var(--default-color), transparent 90%);
    color: var(--default-color);
  }
  
  .tabs .service-item.link.active {
    background-color: var(--surface-color);
  }
  
  .tabs .service-item.link.active .service-icon {
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .tabs .list-check li {
    display: block;
    padding-left: 30px;
    position: relative;
  }
  
  .tabs .list-check li:before {
    content: "\f26e";
    display: inline-block;
    font-family: "bootstrap-icons" !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 0.1rem;
    font-size: 20px;
    left: 0;
    color: var(--accent-color);
  }
  
  /*--------------------------------------------------------------
  # Services 2 Section
  --------------------------------------------------------------*/
  .services-2 .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .services-2 .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .services-2 p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .services-2 .lead {
    line-height: 1.6;
    font-size: 18px;
    font-weight: normal;
    color: var(--default-color);
  }
  
  .services-2 .btn-get-started {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
  }
  
  .services-2 .btn-get-started:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color);
  }
  
  .services-2 .services-item .services-icon {
    color: var(--accent-color);
    margin-bottom: 20px;
  }
  
  .services-2 .services-item .services-icon span {
    font-size: 4rem;
  }
  
  .services-2 .services-item h3 {
    font-size: 17px;
    font-weight: 400;
    color: var(--heading-color);
  }
  
  /*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
  .pricing .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .pricing .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .pricing p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .pricing .lead {
    line-height: 1.6;
    font-size: 18px;
    font-weight: normal;
    color: var(--default-color);
  }
  
  .pricing .btn-get-started {
    background-color: transparent;
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid var(--accent-color);
    transition: 0.3s all ease-in-out;
    font-size: 14px;
    color: var(--accent-color) !important;
  }
  
  .pricing .btn-get-started:hover {
    border-color: var(--accent-color);
    background-color: var(--accent-color);
    color: white !important;
  }
  
  .pricing .pricing-table {
    background-color: var(--surface-color);
    padding: 40px;
    border-radius: 7px;
  }
  
  .pricing .pricing-table h3 {
    color: var(--heading-color);
  }
  
  .pricing .pricing-table .price-wrap {
    color: var(--heading-color);
  }
  
  .pricing .pricing-table .price-wrap sup {
    position: relative;
    top: -20px;
    font-size: 20px;
  }
  
  .pricing .pricing-table .price-wrap .price {
    font-size: 50px;
    color: var(--heading-color);
  }
  
  .pricing .pricing-table .price-wrap .per {
    color: color-mix(in srgb, var(--default-color) 60%, white);
  }
  
  .pricing .pricing-table ul {
    margin-bottom: 30px;
  }
  
  .pricing .pricing-table ul li {
    margin-bottom: 10px;
  }
  
  .pricing .pricing-table ul .exclude {
    text-decoration: line-through;
  }
  
  .pricing .pricing-table.featured-pricing .price {
    color: var(--accent-color);
  }
  
  .pricing .pricing-table.featured-pricing .btn-get-started {
    background-color: var(--accent-color);
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
    color: white !important;
  }
  
  .pricing .pricing-table.featured-pricing .btn-get-started:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color) !important;
  }
  
  /*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
  .faq .content-subtitle {
    font-size: 15px;
    margin-bottom: 10px;
    display: block;
    color: var(--default-color);
  }
  
  .faq .content-title {
    color: var(--heading-color);
    font-size: 22px;
    margin-bottom: 30px;
  }
  
  .faq p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .faq .custom-accordion .accordion-item {
    background-color: color-mix(in srgb, var(--default-color), transparent 98%);
    margin-bottom: 0px;
    position: relative;
    border-radius: 0px;
    overflow: hidden;
  }
  
  .faq .custom-accordion .accordion-item .btn-link {
    display: block;
    width: 100%;
    padding: 15px 0;
    text-decoration: none;
    text-align: left;
    color: var(--default-color);
    border: none;
    padding-left: 40px;
    border-radius: 0;
    position: relative;
    background: var(--surface-color);
  }
  
  .faq .custom-accordion .accordion-item .btn-link:before {
    content: "\f282";
    display: inline-block;
    font-family: "bootstrap-icons" !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: -0.125em;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
  }
  
  .faq .custom-accordion .accordion-item .btn-link[aria-expanded=true] {
    color: var(--accent-color);
  }
  
  .faq .custom-accordion .accordion-item .btn-link[aria-expanded=true]:before {
    font-family: "bootstrap-icons" !important;
    content: "\f286";
    position: absolute;
    color: var(--accent-color);
    top: 50%;
    transform: translateY(-50%);
  }
  
  .faq .custom-accordion .accordion-item .accordion-body {
    padding: 20px 20px 20px 20px;
    color: #865243;
    background-color: #ffe !important;
  }
  
  /*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
  .testimonials p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .testimonials .testimonial {
    max-width: 500px;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .testimonials .testimonial .name {
    font-size: 18px;
    color: var(--heading-color);
  }
  
  .testimonials .testimonial .img-wrap img {
    margin: 0 auto;
    width: 70px;
    border-radius: 50%;
  }
  
  .testimonials .swiper-pagination {
    position: absolute;
    bottom: 0px;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    margin: 0 5px;
    background-color: color-mix(in srgb, var(--default-color) 65%, transparent);
    opacity: 0.3;
  }
  
  .testimonials .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: var(--accent-color);
    opacity: 1;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Section
  --------------------------------------------------------------*/
  .portfolio .portfolio-filters {
    padding: 0;
    margin: 0 auto 20px auto;
    list-style: none;
    text-align: center;
  }
  
  .portfolio .portfolio-filters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 10px 20px;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    margin-bottom: 5px;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    font-family: var(--heading-font);
  }
  
  .portfolio .portfolio-filters li:hover,
  .portfolio .portfolio-filters li.filter-active {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .portfolio .portfolio-filters li:first-child {
    margin-left: 0;
  }
  
  .portfolio .portfolio-filters li:last-child {
    margin-right: 0;
  }
  
  @media (max-width: 575px) {
    .portfolio .portfolio-filters li {
      font-size: 14px;
      margin: 0 0 10px 0;
    }
  }
  
  .portfolio .portfolio-item {
    position: relative;
    overflow: hidden;
  }
  
  .portfolio .portfolio-item .portfolio-info {
    opacity: 0;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: -100%;
    z-index: 3;
    transition: all ease-in-out 0.5s;
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    padding: 15px;
  }
  
  .portfolio .portfolio-item .portfolio-info h4 {
    font-size: 18px;
    font-weight: 600;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    font-size: 14px;
    margin-bottom: 0;
    padding-right: 50px;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link,
  .portfolio .portfolio-item .portfolio-info .details-link {
    position: absolute;
    right: 50px;
    font-size: 24px;
    top: calc(50% - 14px);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    transition: 0.3s;
    line-height: 0;
  }
  
  .portfolio .portfolio-item .portfolio-info .preview-link:hover,
  .portfolio .portfolio-item .portfolio-info .details-link:hover {
    color: var(--accent-color);
  }
  
  .portfolio .portfolio-item .portfolio-info .details-link {
    right: 14px;
    font-size: 28px;
  }
  
  .portfolio .portfolio-item:hover .portfolio-info {
    opacity: 1;
    bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Portfolio Details Section
  --------------------------------------------------------------*/
  .portfolio-details .portfolio-details-slider img {
    width: 100%;
  }
  
  .portfolio-details .swiper-wrapper {
    height: auto;
  }
  
  .portfolio-details .swiper-button-prev,
  .portfolio-details .swiper-button-next {
    width: 48px;
    height: 48px;
  }
  
  .portfolio-details .swiper-button-prev:after,
  .portfolio-details .swiper-button-next:after {
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.15);
    font-size: 24px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
  }
  
  .portfolio-details .swiper-button-prev:hover:after,
  .portfolio-details .swiper-button-next:hover:after {
    background-color: rgba(0, 0, 0, 0.3);
  }
  
  @media (max-width: 575px) {
  
    .portfolio-details .swiper-button-prev,
    .portfolio-details .swiper-button-next {
      display: none;
    }
  }
  
  .portfolio-details .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    background-color: color-mix(in srgb, var(--default-color), transparent 85%);
    opacity: 1;
  }
  
  .portfolio-details .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--accent-color);
  }
  
  .portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
  }
  
  .portfolio-details .portfolio-info h3:after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: var(--accent-color);
    left: 0;
    bottom: 0;
  }
  
  .portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
  }
  
  .portfolio-details .portfolio-info ul li {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  
  .portfolio-details .portfolio-info ul strong {
    text-transform: uppercase;
    font-weight: 400;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 14px;
  }
  
  .portfolio-details .portfolio-info .btn-visit {
    padding: 8px 40px;
    background: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .portfolio-details .portfolio-info .btn-visit:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  .portfolio-details .portfolio-description h2 {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .portfolio-details .portfolio-description p {
    padding: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item {
    padding: 30px 30px 0 30px;
    position: relative;
    background: color-mix(in srgb, var(--default-color), transparent 97%);
    margin-bottom: 50px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid var(--background-color);
    float: left;
    margin: 0 10px 0 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0 5px 0;
    padding-top: 20px;
  }
  
  .portfolio-details .portfolio-description .testimonial-item h4 {
    font-size: 14px;
    color: #6c757d;
    margin: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left,
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--accent-color), transparent 50%);
    font-size: 26px;
    line-height: 0;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  
  .portfolio-details .portfolio-description .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
  }
  
  .portfolio-details .portfolio-description .testimonial-item p {
    font-style: italic;
    margin: 0 0 15px 0 0 0;
    padding: 0;
  }
  
  /*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
  @media (max-width: 575px)  {
    .team .member {
      flex-direction: column;
      align-items: center !important;
      justify-items: center;
    }
  }
  
  .team .member h3 {
    font-size: 20px;
  }
  
  .team .member h3 a {
    color: var(--heading-color);
  }
  
  .team .member .pic {
    margin-bottom: 30px;
  }
  
  .team .member .pic img {
    border-radius: 4px;
  }
  
  .team .member .position {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    color: color-mix(in srgb, var(--heading-color), transparent 30%);
  }
  
  .team p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .team .more {
    position: relative;
    padding-right: 30px;
    display: inline-block;
  }
  
  .team .more span {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 16px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: transparent;
    color: var(--contrast-color);
    line-height: 1.6;
    text-align: center;
    transition: 0.3s all ease;
  }
  
  .team .more span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .team .more:hover {
    color: var(--contrast-color);
  }
  
  .team .more:hover span {
    background: var(--accent-color);
  }
  
  .team .more.dark {
    color: color-mix(in srgb, var(--default-color), black 90%);
  }
  
  .team .more.dark>span {
    color: color-mix(in srgb, var(--default-color), black 90%);
  }
  
  .team .more.dark:hover {
    color: var(--accent-color);
  }
  
  .team .more.dark:hover span {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
  
  .team .slider-nav a {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    background: var(--accent-color);
    border-radius: 4px;
    transition: 0.2s all ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .team .slider-nav a i {
    color: var(--contrast-color);
  }
  
  .team .slider-nav a:hover {
    background-color: color-mix(in srgb, var(--accent-color) 90%, white 15%);
  }
  
  .team .slider-nav a:first-child {
    margin-right: 5px;
  }
  
  
  
  /*--------------------------------------------------------------
  # Blog Posts 2 Section
  --------------------------------------------------------------*/
  .blog-posts-2 article {
    height: 100%;
  }
  
  .blog-posts-2 .post-img {
    max-height: 440px;
    overflow: hidden;
  }
  
  .blog-posts-2 .title {
    font-size: 20px;
    font-weight: 700;
    padding: 0;
    margin: 5px 0;
  }
  
  .blog-posts-2 .title a {
    color: var(--heading-color);
    transition: 0.3s;
  }
  
  .blog-posts-2 .title a:hover {
    color: var(--accent-color);
  }
  
  .blog-posts-2 .meta-top {
    margin-top: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-posts-2 .meta-top ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog-posts-2 .meta-top i {
    font-size: 24px;
    line-height: 0;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .blog-posts-2 .meta-top a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  /*--------------------------------------------------------------
  # Blog Pagination Section
  --------------------------------------------------------------*/
  .blog-pagination {
    padding-top: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog-pagination li a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog-pagination li a.active,
  .blog-pagination li a:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .blog-pagination li a.active a,
  .blog-pagination li a:hover a {
    color: var(--contrast-color);
  }
  
  /*--------------------------------------------------------------
  # Blog Details Section
  --------------------------------------------------------------*/
  .blog-details {
    padding-bottom: 30px;
  }
  
  .blog-details .article {
    padding: 30px;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .blog-details .post-img {
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog-details .title {
    color: var(--heading-color);
    font-size: 28px;
    font-weight: 700;
    padding: 0;
    margin: 30px 0;
  }
  
  .blog-details .content {
    margin-top: 20px;
  }
  
  .blog-details .content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog-details .content blockquote {
    overflow: hidden;
    background-color: color-mix(in srgb, var(--default-color), transparent 95%);
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog-details .content blockquote p {
    color: var(--default-color);
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog-details .content blockquote:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--accent-color);
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog-details .meta-top {
    margin-top: 20px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-details .meta-top ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog-details .meta-top ul li+li {
    padding-left: 20px;
  }
  
  .blog-details .meta-top i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
  }
  
  .blog-details .meta-top a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog-details .meta-bottom {
    padding-top: 10px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .blog-details .meta-bottom i {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    display: inline;
  }
  
  .blog-details .meta-bottom a {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    transition: 0.3s;
  }
  
  .blog-details .meta-bottom a:hover {
    color: var(--accent-color);
  }
  
  .blog-details .meta-bottom .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .blog-details .meta-bottom .cats li {
    display: inline-block;
  }
  
  .blog-details .meta-bottom .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .blog-details .meta-bottom .tags li {
    display: inline-block;
  }
  
  .blog-details .meta-bottom .tags li+li::before {
    padding-right: 6px;
    color: var(--default-color);
    content: ",";
  }
  
  .blog-details .meta-bottom .share {
    font-size: 16px;
  }
  
  .blog-details .meta-bottom .share i {
    padding-left: 5px;
  }
  
  /*--------------------------------------------------------------
  # Blog Comments Section
  --------------------------------------------------------------*/
  .blog-comments {
    padding: 10px 0;
  }
  
  .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog-comments .comment h5 a {
    font-weight: bold;
    color: var(--default-color);
    transition: 0.3s;
  }
  
  .blog-comments .comment h5 a:hover {
    color: var(--accent-color);
  }
  
  .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
  }
  
  .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    margin-bottom: 5px;
  }
  
  .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  /*--------------------------------------------------------------
  # Comment Form Section
  --------------------------------------------------------------*/
  .comment-form {
    padding-top: 10px;
  }
  
  .comment-form form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px color-mix(in srgb, var(--default-color), transparent 90%);
  }
  
  .comment-form form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .comment-form form p {
    font-size: 14px;
  }
  
  .comment-form form input {
    background-color: var(--background-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    font-size: 14px;
    border-radius: 4px;
    padding: 10px 10px;
  }
  
  .comment-form form input:focus {
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .comment-form form textarea {
    background-color: var(--background-color);
    color: var(--default-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 70%);
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
    height: 120px;
  }
  
  .comment-form form textarea:focus {
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .comment-form form .form-group {
    margin-bottom: 25px;
  }
  
  .comment-form form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .comment-form form .btn-primary:hover {
    color: var(--contrast-color);
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
  }
  
  /*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
  .team .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 30px;
    border-radius: 5px;
    background: #fff;
    transition: 0.5s;
    height: 100%;
  }
  
  .team .member .pic {
    overflow: hidden;
    width: 180px;
    border-radius: 50%;
  }
  
  .team .member .pic img {
    transition: ease-in-out 0.3s;
  }
  
  .team .member:hover {
    transform: translateY(-10px);
  }
  
  .team .member .member-info {
    padding-left: 30px;
  }
  
  .team .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: var(--title-color);
  }
  
  .team .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  
  .team .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #cbd6e9;
    bottom: 0;
    left: 0;
  }
  
  .team .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  
  .team .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .team .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #eff2f8;
  }
  
  .team .member .social a i {
    color: #b38f8f;
    font-size: 16px;
    margin: 0 2px;
  }
  
  .team .member .social a:hover {
    background: #b38f8f;
  }
  
  .team .member .social a:hover i {
    color: #fff;
  }
  
  .team .member .social a+a {
    margin-left: 8px;
  }
  
  /*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
  .contact {
    padding-top: 40px;
    margin-top: 30px;
    padding-bottom: 20px
  }
  
  .contact .container {
    background-color: var(--surface-color);
  }
  
  .contact .content {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  
  .contact .service.no-shadow.link {
    box-shadow: none !important;
  }
  
  .contact .service .service-icon {
    font-size: 24px;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    position: relative;
    color: var(--acccent-color);
    background-color: var(--surface-color);
  }
  
  .contact .service .service-icon>svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .contact .service .service-contents h3 {
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 10%);
  }
  
  .contact .service.horizontal .service-icon {
    flex: 0 0 40px;
    margin-right: 30px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
  
  .contact .service.link {
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 7px;
  }
  
  .contact .service.link .service-contents {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    font-size: 14px;
  }
  
  .contact .service.link .service-contents *:last-child {
    margin-bottom: 0;
  }
  
  .contact .service.link:hover,
  .contact .service.link.active {
    background: var(--contrast-color);
    box-shadow: 0 4px 10px -4px rgba(0, 0, 0, 0.05);
  }
  
  .contact .service.link:hover .service-icon,
  .contact .service.link.active .service-icon {
    background-color: color-mix(in srgb, var(--default-color), transparent 75%);
    color: color-mix(in srgb, var(--default-color), transparent 10%);
  }
  
  .contact .service.link.active {
    position: relative;
    overflow: hidden;
  }
  
  .contact .service.link.active .service-icon {
    color: var(--contrast-color);
    background-color: var(--accent-color);
  }
  
  .contact .form-group label {
    display: block;
    margin-bottom: 10px;
  }
  
  .contact .btn-submit {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    border-radius: 30px;
    padding: 8px 30px;
    border: 2px solid transparent;
    transition: 0.3s all ease-in-out;
    font-size: 14px;
  }
  
  .contact .btn-submit:hover {
    border-color: var(--accent-color);
    background-color: transparent;
    color: var(--accent-color);
  }
  
  .contact .form-control {
    border: 2px solid color-mix(in srgb, var(--default-color), transparent 20%);
    background: none;
    height: 42px;
    color: color-mix(in srgb, var(--default-color), transparent 5%);
  }
  
  .contact .form-control:active,
  .contact .form-control:focus {
    outline: none;
    box-shadow: none;
    border-color: var(--accent-color);
  }
  
  .contact textarea.form-control {
    height: auto !important;
  }
  
  .contact .php-email-form {
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),
    url("../../../public/assets/img/woman.jpg") fixed;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 15px
  }
  
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  
  .contact .php-email-form h2 {
    color: #fff;
  }
  
  .contact .php-email-form .form-group input, .contact .php-email-form .form-group textarea {
    background-color: var(--accent-color);
    color: var(--contrast-color);
    border: none;
  }
  
  ::-webkit-input-placeholder {
    color: var(--contrast-color) !important;
  }
  
  :-moz-placeholder { /* Firefox 18- */
    color: var(--contrast-color);  
  }
  
  ::-moz-placeholder {  /* Firefox 19+ */
    color: var(--contrast-color);  
  }
  
  :-ms-input-placeholder {  
    color: var(--contrast-color) !important;  
  }
  
  .contact .php-email-form .error-message {
    display: none;
    color: #ffffff;
    background: #df1529;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .error-message br+br {
    margin-top: 25px;
  }
  
  .contact .php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  
  .contact .php-email-form .loading {
    display: none;
    text-align: center;
    padding: 15px;
  }
  
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--background-color);
    animation: animate-loading 1s linear infinite;
  }
  
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  /*--------------------------------------------------------------
  # Widgets
  --------------------------------------------------------------*/
  .widgets-container {
    margin: 60px 0 30px 0;
  }
  
  .widget-title {
    color: var(--heading-color);
    font-size: 20px;
    font-weight: 600;
    padding: 0 0 0 10px;
    margin: 0 0 20px 0;
    border-left: 4px solid var(--accent-color);
  }
  
  .widget-item {
    margin-bottom: 30px;
    background-color: color-mix(in srgb, var(--default-color), transparent 98%);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    padding: 30px;
    border-radius: 5px;
  }
  
  .widget-item:last-child {
    margin-bottom: 0;
  }
  
  .blog-author-widget img {
    max-width: 160px;
  }
  
  .blog-author-widget h4 {
    font-weight: 600;
    font-size: 24px;
    margin: 15px 0 0 0;
    padding: 0;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
  }
  
  .blog-author-widget .social-links {
    margin: 5px 0;
  }
  
  .blog-author-widget .social-links a {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    margin: 0 3px;
    font-size: 18px;
  }
  
  .blog-author-widget .social-links a:hover {
    color: var(--accent-color);
  }
  
  .blog-author-widget p {
    font-style: italic;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin: 10px 0 0 0;
  }
  
  .search-widget form {
    background: var(--background-color);
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 75%);
    padding: 3px 10px;
    position: relative;
    border-radius: 50px;
    transition: 0.3s;
  }
  
  .search-widget form input[type=text] {
    border: 0;
    padding: 4px 10px;
    border-radius: 4px;
    width: calc(100% - 40px);
    background-color: var(--background-color);
    color: var(--default-color);
  }
  
  .search-widget form input[type=text]:focus {
    outline: none;
  }
  
  .search-widget form button {
    background: none;
    color: var(--default-color);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    font-size: 16px;
    padding: 0 16px;
    transition: 0.3s;
    line-height: 0;
  }
  
  .search-widget form button i {
    line-height: 0;
  }
  
  .search-widget form button:hover {
    color: var(--accent-color);
  }
  
  .search-widget form:is(:focus-within) {
    border-color: var(--accent-color);
  }
  
  .recent-posts-widget .post-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .recent-posts-widget .post-item:last-child {
    margin-bottom: 0;
  }
  
  .recent-posts-widget .post-item h4 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  
  .recent-posts-widget .post-item h4 a {
    color: var(--default-color);
    transition: 0.3s;
  }
  
  .recent-posts-widget .post-item h4 a:hover {
    color: var(--accent-color);
  }
  
  .recent-posts-widget .post-item time {
    display: block;
    font-style: italic;
    font-size: 14px;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
  }
  
  .tags-widget ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .tags-widget ul li {
    display: inline-block;
  }
  
  .tags-widget ul a {
    background-color: color-mix(in srgb, var(--default-color), transparent 94%);
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    border-radius: 50px;
    font-size: 14px;
    padding: 5px 15px;
    margin: 0 6px 8px 0;
    display: inline-block;
    transition: 0.3s;
  }
  
  .tags-widget ul a:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
  }
  
  .tags-widget ul a span {
    padding-left: 5px;
    color: color-mix(in srgb, var(--default-color), transparent 60%);
    font-size: 14px;
  }