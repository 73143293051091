  
  /*--------------------------------------------------------------
    # Dashboard
    --------------------------------------------------------------*/
    
  /* Filter dropdown */
  .Dashboard .dashboard .filter {
    position: absolute;
    right: 0px;
    top: 15px;
  }
  
  .Dashboard .dashboard .filter .icon {
    color: #aab7cf;
    padding-right: 20px;
    padding-bottom: 5px;
    transition: 0.3s;
    font-size: 16px;
  }
  
  .Dashboard .dashboard .filter .icon:hover,
  .Dashboard .dashboard .filter .icon:focus {
    color: #4154f1;
  }
  
  .Dashboard .dashboard .filter .dropdown-header {
    padding: 8px 15px;
  }
  
  .Dashboard .dashboard .filter .dropdown-header h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #aab7cf;
    margin-bottom: 0;
    padding: 0;
  }
  
  .Dashboard .dashboard .filter .dropdown-item {
    padding: 8px 15px;
  }
  
  /* Info Cards */
  .Dashboard .dashboard .info-card {
    padding-bottom: 10px;
  }
  
  .Dashboard .dashboard .info-card h6 {
    font-size: 28px;
    color: #012970;
    font-weight: 700;
    margin: 0;
    padding: 0;
  }
  
  .Dashboard .dashboard .card-icon {
    font-size: 32px;
    line-height: 0;
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  
  .Dashboard .dashboard .sales-card .card-icon {
    color: #4154f1;
    background: #f6f6fe;
  }
  
  .Dashboard .dashboard .revenue-card .card-icon {
    color: #2eca6a;
    background: #e0f8e9;
  }
  
  .Dashboard .dashboard .customers-card .card-icon {
    color: #ff771d;
    background: #ffecdf;
  }
  
  /* Activity */
  .Dashboard .dashboard .activity {
    font-size: 14px;
  }
  
  .Dashboard .dashboard .activity .activity-item .activite-label {
    color: #888;
    position: relative;
    flex-shrink: 0;
    flex-grow: 0;
    min-width: 64px;
  }
  
  .Dashboard .dashboard .activity .activity-item .activite-label::before {
    content: "";
    position: absolute;
    right: -11px;
    width: 4px;
    top: 0;
    bottom: 0;
    background-color: #eceefe;
  }
  
  .Dashboard .dashboard .activity .activity-item .activity-badge {
    margin-top: 3px;
    z-index: 1;
    font-size: 11px;
    line-height: 0;
    border-radius: 50%;
    flex-shrink: 0;
    border: 3px solid #fff;
    flex-grow: 0;
  }
  
  .Dashboard .dashboard .activity .activity-item .activity-content {
    padding-left: 10px;
    padding-bottom: 20px;
  }
  
  .Dashboard .dashboard .activity .activity-item:first-child .activite-label::before {
    top: 5px;
  }
  
  .Dashboard .dashboard .activity .activity-item:last-child .activity-content {
    padding-bottom: 0;
  }
  
  /* Recent Sales */
  .Dashboard .dashboard .recent-sales {
    font-size: 14px;
  }
  
  .Dashboard .dashboard .recent-sales .table thead {
    background: #f6f6fe;
  }
  
  .Dashboard .dashboard .recent-sales .table thead th {
    border: 0;
  }
  
  .Dashboard .dashboard .recent-sales .dataTable-top {
    padding: 0 0 10px 0;
  }
  
  .Dashboard .dashboard .recent-sales .dataTable-bottom {
    padding: 10px 0 0 0;
  }
  
  /* Top Selling */
  .Dashboard .dashboard .top-selling {
    font-size: 14px;
  }
  
  .Dashboard .dashboard .top-selling .table thead {
    background: #f6f6fe;
  }
  
  .Dashboard .dashboard .top-selling .table thead th {
    border: 0;
  }
  
  .Dashboard .dashboard .top-selling .table tbody td {
    vertical-align: middle;
  }
  
  .Dashboard .dashboard .top-selling img {
    border-radius: 5px;
    max-width: 60px;
  }


/* Card */
.Dashboard .Dashboard .card {
    margin-bottom: 30px;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
  }
  
  .Dashboard .Dashboard .card-header,
  .Dashboard .Dashboard .card-footer {
    border-color: #ebeef4;
    background-color: #fff;
    color: #798eb3;
    padding: 15px;
  }
  
  .Dashboard .Dashboard .card-title {
    padding: 20px 0 15px 0;
    font-size: 18px;
    font-weight: 500;
    color: #012970;
    font-family: "Poppins", sans-serif;
  }
  
  .Dashboard .Dashboard .card-title span {
    color: #899bbd;
    font-size: 14px;
    font-weight: 400;
  }
  
  .Dashboard .Dashboard .card-body {
    padding: 0 20px 20px 20px;
  }
  
  .Dashboard .Dashboard .card-img-overlay {
    background-color: rgba(255, 255, 255, 0.6);
  }