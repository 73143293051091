/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
  .sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 300px;
    z-index: 996;
    transition: all 0.3s;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #865243 transparent;
    box-shadow: 0px 0px 20px rgba(112, 1, 1, 0.1);
    background-color: #fff;
  }
  
  @media (max-width: 1199px) {
    .sidebar {
      left: -300px;
    }
  }
  .sidebar::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #fff;
  }
  .sidebar::-webkit-scrollbar-thumb {
    background-color: #865243;
  }
  
  @media (min-width: 1200px) {
    #main,
    #footer {
      margin-left: 300px;
    }
  }
  
  @media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
      left: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
      margin-left: 0;
    }
  
    .toggle-sidebar .sidebar {
      left: -300px;
    }
  }
  .sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav .nav-item {
    margin-bottom: 5px;
  }
  .sidebar-nav .nav-heading {
    font-size: 11px;
    text-transform: uppercase;
    color: #b38f8f;
    font-weight: 600;
    margin: 10px 0 5px 15px;
  }
  .sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #b38f8f;
    transition: 0.3;
    background: #f6f9ff;
    padding: 10px 15px;
    border-radius: 4px;
  }
  .sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #b38f8f;
  }
  .sidebar-nav .nav-link.collapsed {
    color: #865243;
    background: #fff;
  }
  .sidebar-nav .nav-link.collapsed i {
    color: #865243;
  }
  .sidebar-nav .nav-link:hover {
    color: #b38f8f;
    background: #f6f9ff;
  }
  .sidebar-nav .nav-link:hover i {
    color: #b38f8f;
  }
  .sidebar-nav .nav-link .bi-chevron-down {
    margin-right: 0;
    transition: transform 0.2s ease-in-out;
  }
  .sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
  }
  .sidebar-nav .nav-content {
    padding: 5px 0 0 0;
    margin: 0;
    list-style: none;
  }
  .sidebar-nav .nav-content a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #865243;
    transition: 0.3;
    padding: 10px 0 10px 40px;
    transition: 0.3s;
  }
  .sidebar-nav .nav-content a i {
    font-size: 6px;
    margin-right: 8px;
    line-height: 0;
    border-radius: 50%;
  }
  .sidebar-nav .nav-content a:hover,
  .sidebar-nav .nav-content a.active {
    color: #b38f8f;
  }
  .sidebar-nav .nav-content a.active i {
    background-color: #b38f8f;
  }