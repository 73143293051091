/*--------------------------------------------------------------
# Team Section
--------------------------------------------------------------*/


@media (max-width: 575px)  {
    .team .member {
      flex-direction: column;
      align-items: center !important;
      justify-items: center;
    }
    .ReactModal__Content {
      width: 100%;
      inset: 0 !important;
    }
  }
  
  .team .member h3 {
    font-size: 20px;
  }
  
  .team .member h3 a {
    color: #b38f80 !important;
    text-decoration: none !important;
  }
  
  .team .member .pic {
    margin-bottom: 30px;
    height: max-content;
  }
  
  .team .member .pic img {
    border-radius: 50%;
    max-height: 100%;
    object-fit: cover;
  }
  
  .team .member .position {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    color: color-mix(in srgb, var(--heading-color), transparent 30%);
  }
  
  .team p {
    line-height: 1.7;
    color: var(--default-color);
  }
  
  .team .more {
    position: relative;
    padding-right: 30px;
    display: inline-block;
  }
  
  .team .more span {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 16px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: transparent;
    color: var(--contrast-color);
    line-height: 1.6;
    text-align: center;
    transition: 0.3s all ease;
  }
  
  .team .more span:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .team .more:hover {
    color: var(--contrast-color);
  }
  
  .team .more:hover span {
    background: var(--accent-color);
  }
  
  .team .more.dark {
    color: color-mix(in srgb, var(--default-color), black 90%);
  }
  
  .team .more.dark>span {
    color: color-mix(in srgb, var(--default-color), black 90%);
  }
  
  .team .more.dark:hover {
    color: var(--accent-color);
  }
  
  .team .more.dark:hover span {
    color: var(--contrast-color);
    background: var(--accent-color);
  }
  
  .team .slider-nav button {
    display: inline-block;
    width: 40px;
    height: 40px;
    position: relative;
    border: none;
    background: var(--accent-color);
    border-radius: 4px;
    transition: 0.2s all ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .team .slider-nav button i {
    color: var(--contrast-color);
  }
  
  .team .slider-nav button:hover {
    background-color: color-mix(in srgb, var(--accent-color) 90%, white 15%);
  }
  
  .team .slider-nav button:first-child {
    margin-right: 5px;
  }
  