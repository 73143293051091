.ReactModal__Overlay--after-open {
    z-index: 999;
    background-color: rgb(255, 255, 255)
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Content--after-open {
    border: none !important;
    height: auto;
}

.about .content h2 {
    font-weight: 700;
    font-size: 24px;
  }
  
  .about .content ul {
    list-style: none;
    padding: 0;
  }
  
  .about .content ul li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }
  
  .about .content ul strong {
    margin-right: 10px;
  }
  
  .about .content ul i {
    font-size: 16px;
    margin-right: 5px;
    color: var(--accent-color);
    line-height: 0;
  }
  