.aboutUs {

}

.aboutUs .marketing {
    
}

.aboutUs .marketing .featurette {

}

.aboutUs .marketing .featurette .image {
    width: 100%;
    object-fit: cover;
    height: 500px;
    object-position: top;
}